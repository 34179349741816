



























































































import { Component, Mixins } from 'vue-property-decorator';
import DialogMixin from '@/mixins/dialog';
import AppointmentHeader from './AppointmentHeader.vue';
import ThirdStepAppointment from './ThirdStepAppointment.vue';
import SecondStepAppointment from './SecondStepAppointment.vue';
import { appointmentModule } from '@/store/modules/appointment';
import { typeModel } from '@/filters/type-model';

@Component({
    filters: { typeModel },
    components: {
        SecondStepAppointment,
        ThirdStepAppointment,
        AppointmentHeader
    }
})
export default class RegisterAppointmentByAgent extends Mixins(DialogMixin) {
    form = { date: '', hour: '' };

    get model() {
        return appointmentModule.selectedModel;
    }

    error = '';
    next() {
        this.active++;
    }

    async store() {
        try {
            await appointmentModule.registerAppointment({
                clienteId: this.selectedCustomer._id,
                esClientePrincipal: this.selectedCustomer.type === 'principal',
                projectId: this.model.project._id,
                fecha: this.form.date,
                hora: this.form.hour,
                typologyId: this.model._id
            });
            this.next();
        } catch ({ mensaje }) {
            this.error =
                typeof mensaje === 'string'
                    ? mensaje
                    : 'Lo sentimos, pero no podemos procesar tu solicitud. Inténtalo nuevamente.';
        }
    }

    close() {
        appointmentModule.closeOrOpenDialog(false);
    }
}
