




















































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class AutoComplete<T extends Record<string, any>> extends Vue {
    @Prop({ required: true }) readonly fetchSuggestions!: Function;
    @Prop({ type: String }) readonly placeholder!: string;
    @Prop({ type: String }) readonly type!: string;
    @Prop({ type: Number }) readonly rows!: number;
    @Prop({ type: Boolean, default: true }) readonly cache!: boolean;
    @Prop({ type: Number }) readonly maxlength!: number;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: String }) readonly valueKey!: string;
    @Prop({ type: String }) readonly size!: string;
    @Prop({ type: String }) readonly value!: string | undefined;
    @Prop({ type: Boolean, default: false }) readonly onlyOne!: boolean;
    @Prop() readonly fetchOneSuggestion!: Function;
    private ownSearch: undefined | string = '';
    private loading = false;
    private results: T[] = [];

    get search(): string | undefined {
        return this.value === undefined ? this.ownSearch : this.value;
    }

    set search(value: string | undefined) {
        this.input(value);
    }

    get firstkey() {
        return this.valueKey.split(',');
    }

    fetchSuggestion(queryString: string, cb: Function) {
        if (this.search) {
            const elements = this.results;
            let results = this.cache
                ? queryString
                    ? elements.filter(this.createFilter(queryString))
                    : elements
                : [];
            if (results.length < 1) {
                this.fetchSuggestions(queryString, (suggestions: T[]) => {
                    this.results = this.cache
                        ? this.results.concat(
                              suggestions.filter(element =>
                                  this.results.every(
                                      result => result !== element
                                  )
                              )
                          )
                        : suggestions;
                    results = this.cache
                        ? this.results.filter(this.createFilter(queryString))
                        : this.results;
                    cb(results);
                });
            } else {
                cb(results);
            }
        } else {
            cb([]);
        }
    }

    selectOne() {
        this.loading = true;
        this.fetchOneSuggestion(this.search, (value: string) => {
            this.loading = false;
            if (value) {
                this.input();
            }
        });
    }

    createFilter(queryString: string) {
        return (element: any) => {
            const val = this.normalizeText(queryString);
            let item = '';
            if (this.firstkey.length > 1) {
                item = this.normalizeText(this.valuekey(element));
            } else {
                item = this.normalizeText(element[this.firstkey[0]]);
            }
            return item.includes(val);
        };
    }

    @Emit()
    select(element: T) {
        this.input();
        return element;
    }

    @Emit()
    input(event?: string) {
        this.ownSearch = event;
        return event;
    }

    private valuekey(element: T) {
        return this.firstkey
            .reduce((array, key) => array.concat(element[key]), [])
            .join(' ');
    }

    private normalizeText(queryString?: string): string {
        return queryString
            ? queryString
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
            : '';
    }

    private searchText(value: string) {
        if (!value) return '';
        if (!this.search) return '';
        const re = new RegExp(
            '(' +
                this.search
                    .trim()
                    .split(/\s+/)
                    .join('|') +
                ')',
            'gi'
        );
        return value.replace(re, `<b class="text-info">$1</b>`);
    }
}
